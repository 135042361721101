<template>
  <!-- 底部图表 -->
  <el-drawer
    :title="drawerTitle"
    class="drawer-chart"
    :visible.sync="drawer"
    :destroy-on-close="true"
    direction="btt"
    @closed="drawerClosed"
  >
    <x-chart
      class="high"
      v-loading="loading"
      id="wave-chart"
      :option="option"
    ></x-chart>
  </el-drawer>
</template>

<script>
// <script module = 'waveChartContainer' lang="renderjs">
import Highcharts from "highcharts";
import windbarb from "highcharts/modules/windbarb";
windbarb(Highcharts);

import XChart from "./Charts";

export default {
  name: "WaveChart",
  props: {
    // id: {
    //   type: String,
    //   default: 'wave-chart'
    // },
    // option: {
    //   type: Object
    // }
    imgPoint: {
      type: Object,
    },
  },
  components: {
    XChart,
  },
  data() {
    return {
      loading: false,
      drawer: false,
      drawerTitle: "图表",
      option: {},
      xAxisData: [],
      yAxisData: [],
    };
  },
  mounted() {
    this.drawer = true;
    this.getData();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    drawerClosed() {
      this.$emit("closed");
    },
    getData() {
      let url =
        this.$mapConfig.mapUrl[2] +
        `api/waveData/getDataList.json?longitude=${this.imgPoint.imglon}&latitude=${this.imgPoint.imglat}`;
      this.loading = true;
      this.drawerTitle = `海浪（东经${this.imgPoint.lon}° 北纬${this.imgPoint.lat}°）`;
      document.getElementById("el-drawer__title").children[1].innerHTML =
        "<div>X</div>";
      // this.$axios.post(url)
      this.$axios({
        method: "post",
        url: url,
        headers: { token: "XG5medfPW41LQZ0sj8WXPbgGaNq9hh3MMzkGIETVws" },
      })
        .then((res) => {
          if (res && res.data.success) {
            res.data.result.forEach((element) => {
              this.xAxisData.push(
                new Date(
                  (element.currentTime + "").replace(
                    /^(\d{4})(\d{2})(\d{2})(\d{2})$/,
                    "$1/$2/$3 $4:"
                  )
                )
              );
              this.yAxisData.push(parseFloat(element.height));
            });
            this.initChart();
            setTimeout(() => {
              document.getElementsByClassName("drawer-chart")[0].style.top =
                "unset";
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    initChart() {
      // var sdata = [
      //   {
      //     "angle": "40.0",
      //     "currentTime": 2020112300,
      //     "rowLocked": 0,
      //     "speed": "0.68"
      //   },
      //   {
      //     "angle": "47.0",
      //     "currentTime": 2020112302,
      //     "rowLocked": 0,
      //     "speed": "0.60"
      //   },
      //   {
      //     "angle": "46.0",
      //     "currentTime": 2020112303,
      //     "rowLocked": 0,
      //     "speed": "0.42"
      //   },
      //   {
      //     "angle": "38.0",
      //     "currentTime": 2020112304,
      //     "rowLocked": 0,
      //     "speed": "0.34"
      //   },
      //   {
      //     "angle": "42.0",
      //     "currentTime": 2020112311,
      //     "rowLocked": 0,
      //     "speed": "0.55"
      //   },
      //   {
      //     "angle": "35.0",
      //     "currentTime": 2020112318,
      //     "rowLocked": 0,
      //     "speed": "0.38"
      //   },
      //   {
      //     "angle": "32.0",
      //     "currentTime": 2020112319,
      //     "rowLocked": 0,
      //     "speed": "0.40"
      //   },
      //   {
      //     "angle": "27.0",
      //     "currentTime": 2020112320,
      //     "rowLocked": 0,
      //     "speed": "0.42"
      //   },
      //   {
      //     "angle": "47.0",
      //     "currentTime": 2020112322,
      //     "rowLocked": 0,
      //     "speed": "0.35"
      //   },
      //   {
      //     "angle": "51.0",
      //     "currentTime": 2020112404,
      //     "rowLocked": 0,
      //     "speed": "0.46"
      //   },
      //   {
      //     "angle": "34.0",
      //     "currentTime": 2020112406,
      //     "rowLocked": 0,
      //     "speed": "0.32"
      //   },
      //   {
      //     "angle": "24.0",
      //     "currentTime": 2020112408,
      //     "rowLocked": 0,
      //     "speed": "0.44"
      //   },
      //   {
      //     "angle": "43.0",
      //     "currentTime": 2020112410,
      //     "rowLocked": 0,
      //     "speed": "0.34"
      //   },
      //   {
      //     "angle": "43.0",
      //     "currentTime": 2020112411,
      //     "rowLocked": 0,
      //     "speed": "0.40"
      //   },
      //   {
      //     "angle": "40.0",
      //     "currentTime": 2020112413,
      //     "rowLocked": 0,
      //     "speed": "0.68"
      //   },
      //   {
      //     "angle": "43.0",
      //     "currentTime": 2020112414,
      //     "rowLocked": 0,
      //     "speed": "0.83"
      //   },
      //   {
      //     "angle": "41.0",
      //     "currentTime": 2020112417,
      //     "rowLocked": 0,
      //     "speed": "0.55"
      //   },
      //   {
      //     "angle": "28.0",
      //     "currentTime": 2020112422,
      //     "rowLocked": 0,
      //     "speed": "0.41"
      //   },
      //   {
      //     "angle": "42.0",
      //     "currentTime": 2020112501,
      //     "rowLocked": 0,
      //     "speed": "0.47"
      //   },
      //   {
      //     "angle": "48.0",
      //     "currentTime": 2020112505,
      //     "rowLocked": 0,
      //     "speed": "0.51"
      //   },
      //   {
      //     "angle": "25.0",
      //     "currentTime": 2020112509,
      //     "rowLocked": 0,
      //     "speed": "0.42"
      //   },
      //   {
      //     "angle": "42.0",
      //     "currentTime": 2020112515,
      //     "rowLocked": 0,
      //     "speed": "0.81"
      //   },
      //   {
      //     "angle": "41.0",
      //     "currentTime": 2020112517,
      //     "rowLocked": 0,
      //     "speed": "0.76"
      //   },
      //   {
      //     "angle": "35.0",
      //     "currentTime": 2020112519,
      //     "rowLocked": 0,
      //     "speed": "0.44"
      //   }
      // ]
      // var data = sdata.map(element => {
      //   return parseFloat(element.speed)
      // });
      Highcharts.setOptions({
        global: {
          useUTC: false, //关闭UTC
        },
      });
      // Construct the chart
      this.option = {
        title: {
          text: null,
        },
        xAxis: {
          categories: this.xAxisData,
          type: "datetime",
          labels: {
            step: 6,
            overflow: "justify",
            formatter: function () {
              return Highcharts.dateFormat("%d日%H时", this.value);
            },
          },
          offset: 0,
        },
        yAxis: [
          {
            labels: {
              format: "{value}",
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
            title: {
              text: "浪高m",
              style: {
                color: Highcharts.getOptions().colors[1],
              },
            },
          },
        ],
        // plotOptions: {
        //   series: {
        //     pointStart: Date.UTC(2017, 0, 29),
        //     pointInterval: 36e5
        //   }
        // },
        tooltip: {
          formatter: function () {
            var d = new Date(this.x);
            var s =
              "<b>" +
              d.getFullYear() +
              "年" +
              (d.getMonth() + 1) +
              "月" +
              d.getDate() +
              "日" +
              d.getHours() +
              "时" +
              "</b><br/>";
            s +=
              '<br/><span style="color:' +
              this.color +
              '">\u25CF' +
              this.series.name +
              ": " +
              this.y.toFixed(2) +
              "m </span>";
            return s;
          },
        },
        series: [
          {
            name: "浪高",
            type: "areaspline",
            keys: ["y", "rotation"], // rotation is not used here
            data: this.yAxisData,
            color: Highcharts.getOptions().colors[0],
            fillColor: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0.25)
                    .get(),
                ],
              ],
            },
            threshold: null,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.drawer-chart {
  z-index: 9999 !important;
  font-size: 0.18rem;
  width: 100%;
  background-color: white;
  position: fixed !important;
  bottom: 0;
  top: 1000px;

  ::v-deep .el-drawer__header {
    padding: 0.1rem;
    margin-bottom: 0;

    span[role="heading"] {
      text-align: center;
      font-size: 0.2rem;
      font-weight: 600;
    }
    span:focus {
      outline: 0 !important;
    }
  }
}
</style>
